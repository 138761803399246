/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9999;
  background: #f7f7f7;
}

.page-loader {
  padding: 0;
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px solid rgba(125, 125, 125, 0.15);
  border-radius: 100%;
  background-image: url("../../assets/globe.jpg");
  background-position-y: center;
  background-size: 220px;
  animation: globeAnim 10s infinite linear;
  transform: rotate(23.5deg);
}

.page-loader::after {
  content: "";
  inset: 0px -1px;
  box-shadow: inset -15px 0px 20px 0px #1e1e1e;
  border-radius: 50%;
  position: absolute;
}

@keyframes globeAnim {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -220px;
  }
}

.hide {
  display: none;
}

.page-loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.page-loader span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: #5cb85c;
  border-radius: 50%;
  transform: scale(0);
  animation: animate 3s linear infinite;
  animation-delay: calc(0.15s * var(--i));
}

.plane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: rotating 3s linear infinite;
  animation-delay: -1.5s;
}
.page-loader .plane:before {
  content: "\f072";
  font-family: fontAwesome;
  position: absolute;
  top: 110px;
  left: 115px;
  color: #5cb85c;
  font-size: 50px;
  transform: rotate(180deg);
}

@keyframes animate {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  80%,
  100% {
    transform: scale(0);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(370deg);
  }
}
