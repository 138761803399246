.add-flight,.added-flights {
  position: absolute;
  padding: 20px;
  z-index: 9;
  margin-top: 50px;
  transition: all 0.5s ease;
  left: 100%;
}
.added-flights{
  z-index: 8;
}
.add-flight.show-flight-list{
  left: 95%;
  transform: translateX(-100%);
}

.add-flight.show-flight-form{
  left: 95%;
  transform: translateX(-100%);
}

.add-flight .ant-card {
  background-color: #ffffff;
}

.add-flight .ant-btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.add-flight .ant-btn-primary:hover {
  background-color: #0056b3;
}
