@import url("../../index.css");
/* CSS to hide scroll bar */

.scrollbar-none {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.scrollbar-none::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari) */
}

/* CSS to hide scroll bar */

/* Burger Button */
#burger-button {
  width: 25px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#burger-button span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#burger-button span:nth-child(1) {
  top: 8px;
}

#burger-button span:nth-child(2) {
  top: 16px;
}

#burger-button span:nth-child(3) {
  top: 24px;
}

#burger-button.open span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#burger-button.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#burger-button.open span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
/* Burger Button */

/* Navbar Header */

/* .navbar-header {
  height: 10%;
  background-color: #e3f2fd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
} */
/* Navbar Header */

/* Sidebar */

.side-bar {
  background-color: var(--cyan-700);
  height: 100%;
  padding: 10px;
  overflow: auto;
  position: relative;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.render-component{
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  height: 100%;
}
.sidebar-open {
  width: 20%;
}
.sidebar-close {
  width: 5%;
}

.sidebar-open+.render-component{
  width: 80%;
}
.sidebar-close+.render-component{
  width: 95%;
}
@media screen and (max-width: 540px) {
  .sidebar-open {
    width: 30%;
    position: absolute;
    z-index: 9999;
  }
  .sidebar-close {
    width: 10%;
  }
  .sidebar-open+.render-component{
    width: 100%;
  }
  .sidebar-close+.render-component{
    width: 90%;
  }
}
.sidebar-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  background-color: var(--cyan-900);
  border-radius: 5px;
}
.sidebar-heading span {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.content-list, .sub-list {
  margin: 10px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.content-list .list {
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.content-list .list.active > span {
  color: lightgrey;
  border-bottom: 2px solid lightgrey;
}
.sidebar-close .content-list .list.active > .icon {
  border-bottom: 2px solid lightgrey;
}
/* .content-list .list span {
  margin-left: 10px;
  display: inline-block;
} */
.sub-list {
  margin-left: 30px;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  list-style-type: none;
  will-change: max-height;
  -webkit-transition: max-height 0.3s ease-in-out;
  -moz-transition: max-height 0.3s ease-in-out;
  -o-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}
.content-list .list.active + .sub-list.sublist-open {
  max-height: 500px;
}
.social-part {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}
/* Sidebar */

/* Loader */
.loader {
  margin-left: 40px;

  stroke-linecap: round;
}

circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
  animation-name: preloader;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  transform-origin: 170px 170px;
  will-change: transform;
}

circle:nth-of-type(1) {
  stroke-dasharray: 550px;
}

circle:nth-of-type(2) {
  stroke-dasharray: 500px;
}

circle:nth-of-type(3) {
  stroke-dasharray: 450px;
}

circle:nth-of-type(4) {
  stroke-dasharray: 300px;
}

circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@-webkit-keyframes preloader {
  50% {
    transform: rotate(360deg);
  }
}

@keyframes preloader {
  50% {
    transform: rotate(360deg);
  }
}
/* Loader */