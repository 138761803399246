@import url("../../index.css");

#map {
  width: 100%;
  height: calc(100% - 20px);
  position: relative;
  top: 0;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  overflow-x: hidden;
}
/* #map.reduce-height {
  height: 55%;
} */
.main-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.table-container {
  padding: 0 10px;
  height: 20px;
  width: 100%;
  position: absolute;
  z-index: 11;
  bottom: 0;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}

.table-container.table-open {
  height: 100%;
}
.handle-container {
  display: flex;
  justify-content: flex-start;
  background-color: white;
  width: fit-content;
  height: 18px;
  align-items: center;
  gap: 8px;
  margin-left: 20px;
  border-radius: 5px 5px 0 0;
  padding: 0 8px;
  border: 1px solid var(--cyan-800);
  border-bottom: none;
}

.table-handle {
  position: relative;
  width: 60px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-handle span {
  position: absolute;
  height: 2px;
  width: 10px;
  background-color: var(--cyan-800);
  border-radius: 100vw;
  z-index: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.table-handle span:nth-child(1) {
  left: 50%;
  -webkit-transform: translateX(-75%) rotate(-60deg);
  -moz-transform: translateX(-75%) rotate(-60deg);
  -o-transform: translateX(-75%) rotate(-60deg);
  transform: translateX(-75%) rotate(-60deg);
}
.table-handle span:nth-child(2) {
  left: 50%;
  -webkit-transform: translateX(-25%) rotate(60deg);
  -moz-transform: translateX(-25%) rotate(60deg);
  -o-transform: translateX(-25%) rotate(60deg);
  transform: translateX(-25%) rotate(60deg);
}
.table-container.table-open .table-handle span:nth-child(1) {
  left: 50%;
  -webkit-transform: translateX(-75%) rotate(-300deg);
  -moz-transform: translateX(-75%) rotate(-300deg);
  -o-transform: translateX(-75%) rotate(-300deg);
  transform: translateX(-75%) rotate(-300deg);
}
.table-container.table-open .table-handle span:nth-child(2) {
  left: 50%;
  -webkit-transform: translateX(-25%) rotate(300deg);
  -moz-transform: translateX(-25%) rotate(300deg);
  -o-transform: translateX(-25%) rotate(300deg);
  transform: translateX(-25%) rotate(300deg);
}

.table-content {
  background-color: var(--cyan-50);
  height: calc(100% - 18px);
  border-radius: 10px 10px 0 0;
  /* padding: 5px; */
}

/* --------------------- */
.hide-calendar-text::-webkit-datetime-edit,
.hide-calendar-text::-webkit-inner-spin-button,
.hide-calendar-text::-webkit-clear-button {
  display: none;
}
.hide-calendar-icon::-webkit-calendar-picker-indicator {
  display: none;
}
.hide-calendar-icon {
  width: 70% !important;
}
.btn,
.btn:active {
  background-color: var(--cyan-800) !important;
  color: white !important;
  border: var(--cyan-800) !important;
}
.btn:hover {
  background-color: var(--cyan-900);
  color: white;
}

/* CSS to hide scroll bar */
.scrollbar-none {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.scrollbar-none::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari) */
}
/* CSS to hide scroll bar */
.aircraft-table .ant-table {
  font-size: 12px !important;
}
.custome-overlay {
  background: white;
  padding: 8px 10px;
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -135%);
  position: relative;
  font-size: 12px;
}
.custome-overlay::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
}
.separation-indicator {
  /* height: 30px;
  width: 30px; */
  border-radius: 100vw;
  transform: translate(-50%, -50%);
  border: 2px solid #0000ff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  height: 3px;
  width: 3px;
  background-color: #0000ff;
  border-radius: 50%;
}
.toggle-flights {
  height: 100%;
}
.toggle-flights .btn {
  border-radius: 0;
}
.toggle-flights .active-indicator{
  position: relative;
}

.toggle-flights .active-indicator::after{
  content: "";
  position: absolute;
  width: 80%;
  height: 3px;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 999px;
}

#map .ant-radio-button-wrapper-checked {
  background-color: var(--cyan-800) !important;
  border-color: var(--cyan-800) !important;
}
#map .ant-radio-button-wrapper-checked::before {
  background-color: var(--cyan-800) !important;
}

#map .ant-radio-button-wrapper-checked:hover {
  background-color: var(--cyan-900); /* Hover color when checked */
  color: #fff;
}
.ant-table-row.on-hold,
.ant-table-row.on-hold > td,
.ant-table-row.on-hold:hover,
.ant-table-row.on-hold > td:hover {
  background-color: #fff2e8 !important;
}

#map .options-panel {
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cyan-800);
  border-radius: 3px;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
